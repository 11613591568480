.View {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.ViewBubble {
  display: flex;
  flex-direction: column;
  border: 4px solid white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  align-items: center;
  justify-content: center;
  max-height: 450px;
  width: 500px;
  > h1 {
    font-size: 1.8rem;
  }
  > h2 {
    font-size: 1.4rem;
  }
}
