html {
  /* Sets up rem font sizing to be power of 10 */
  /* 1rem = 10px;
  /* 1.2rem = 12px;
  /* 1.4rem = 14px;
  /* https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #DD2C56;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 10.4rem;
}

h2 {
  font-size: 6.7rem;
  margin-top: 5px;
  margin-bottom: 10px;
}

p {
  font-size: 1.72rem;
}

span {
  font-size: 1.72rem;
}