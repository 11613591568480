.ProviderList {
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  > li {
    margin-bottom: 10px;
  }
  .ProviderButton {
    background-color: white;
    color: #DD2C56;
    border-radius: 3px;
    padding: 10px 40px;
    border: 3px solid white;
    appearance: none;
    cursor: pointer;
  }
}
